@media only screen and (max-width: 768px){
    div {
        max-width: fit-content;
        margin: 0 auto;
    }
    .venue_image_container {
        width: 80%;
    }
}

.join_page_div {
    width: 500px;
    margin: 0 auto;
    border: black 2px solid;
    border-radius: 1em;
    padding: 2em;
}

.pair_code_display {
    font-size: 1.5em;
    margin-bottom: 1em;
    font-weight: bold;
}

.venue_sort_card {
    width: 60%;
    max-width: 500px;
    min-width: 500px;
    margin: 0 auto;
    border: black 2px solid;
    border-radius: 1em;
    padding: 2em;
}

.img_logos {
    display: grid;
    grid-template-columns: 75% 25%;
}

.logos {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
}

.logos img {
    height: 50px;
    width: 50px;
    margin: 8px
}

.venue_image_container{
    border-radius: 10px;
    position: relative;
    width: 300px;
    height: 200px;
    overflow: hidden;    
 }


.venue_image {
   max-width: inherit;
   max-height: inherit;
   height: inherit;
   width: inherit;
   object-fit: cover;
}

.venue_info_container {
    font-family: 'Oswald', sans-serif;
    margin-top: -10px;
    text-align: left;
}

button {
    padding: 10px;
    font-size: 1em;
    min-width: 8em;
}

p {
    font-size: 1em;
}

.votebuttons{
    padding-top: 30px;
    margin: 0 auto;
    width: 60%;
    display: flex;
    justify-content: space-around;
   
}

.votebuttons img {
    width: 60px;
     height: auto
}

.venue_title {
    font-size: 1.5em;
    margin-bottom: 10px;
    
}

.price img{
    height: 15px;
    margin-right: -5px;
}

.spinner {
    margin-top: 100px;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }